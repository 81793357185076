<template>
  <svg
    width="18"
    height="21"
    viewBox="0 0 18 21"
    fill="inherit"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 1.00001C12.0829 0.999937 12.6552 1.15665 13.1567 1.45374C13.6583 1.75082 14.0707 2.17734 14.3507 2.68859C14.6308 3.19984 14.7682 3.777 14.7485 4.3596C14.7289 4.94221 14.5529 5.5088 14.239 6.00001H16.75C17.44 6.00001 18 6.56001 18 7.25001V10.75C18.0001 11.0382 17.9006 11.3175 17.7183 11.5407C17.5361 11.764 17.2823 11.9174 17 11.975V17.75C17.0001 18.5801 16.6824 19.3788 16.1123 19.9822C15.5422 20.5856 14.7628 20.948 13.934 20.995L13.75 21H4.25C3.41986 21.0001 2.62117 20.6825 2.01777 20.1123C1.41437 19.5422 1.052 18.7628 1.005 17.934L1 17.75V11.975C0.43 11.859 0 11.355 0 10.75V7.25001C0 6.56001 0.56 6.00001 1.25 6.00001H3.761C3.31242 5.30306 3.14707 4.46107 3.29875 3.64624C3.45044 2.83141 3.90771 2.10532 4.57704 1.61648C5.24636 1.12765 6.07716 0.913003 6.8995 1.01645C7.72185 1.1199 8.4736 1.53363 9.001 2.17301C9.30545 1.80551 9.68732 1.50981 10.1193 1.30704C10.5513 1.10426 11.0228 0.99942 11.5 1.00001ZM8.25 11.999H2.5V17.75C2.50002 18.1892 2.66517 18.6123 2.96268 18.9354C3.26019 19.2585 3.6683 19.4579 4.106 19.494L4.25 19.5H8.25V11.999ZM15.5 11.999H9.75V19.5H13.75C14.1893 19.5 14.6126 19.3347 14.9357 19.037C15.2588 18.7392 15.4581 18.3309 15.494 17.893L15.5 17.75V11.999ZM8.25 7.50001H1.5V10.5L8.25 10.499V7.50001ZM16.5 10.5V7.50001H9.75V10.499L16.5 10.5ZM11.5 2.50001C11.0359 2.50001 10.5908 2.68438 10.2626 3.01257C9.93437 3.34076 9.75 3.78588 9.75 4.25001V5.99901H11.524L11.644 5.99401C12.094 5.95665 12.5121 5.74682 12.811 5.40838C13.1098 5.06994 13.2663 4.62908 13.2477 4.17794C13.2291 3.72681 13.0369 3.30032 12.7112 2.98762C12.3855 2.67492 11.9515 2.50021 11.5 2.50001ZM6.5 2.50001C6.04848 2.50021 5.61452 2.67492 5.28881 2.98762C4.96311 3.30032 4.77086 3.72681 4.75227 4.17794C4.73368 4.62908 4.89017 5.06994 5.18904 5.40838C5.48792 5.74682 5.90603 5.95665 6.356 5.99401L6.476 5.99901H8.25V4.25001L8.244 4.10601C8.20786 3.66831 8.00846 3.26019 7.68539 2.96269C7.36232 2.66518 6.93919 2.50002 6.5 2.50001Z"
      fill="inherit"
    />
  </svg>
</template>
<script>
export default {
  name: 'FiveOffersIcon',
}
</script>
